import { api } from '../axios'

const UsageService = (() => {
  const fetchUsageSummary = async filters => {
    const res = await api.get(
      `/jera/stats-summary?fromDate=${filters.dateRange.from}&toDate=${filters
        .dateRange.to || filters.dateRange.from}&groupBy=${filters.groupBy ||
        ''}`
    )

    return res.data
  }

  const fetchUsageDetailed = async (filters, page = 1) => {
    const dateFilter = `fromDate=${filters.period.from}&toDate=${filters.period
      .to || filters.period.from}`
    const originFilter = filters.origin && `origin=${filters.origin.id}`
    const packageFilter = filters.package && `packageId=${filters.package.id}`
    const accountFilter = filters.account && `accountId=${filters.account.id}`
    const serviceNameFilter =
      filters.serviceName && `codeName=${filters.serviceName}`

    const query = [
      dateFilter,
      originFilter,
      packageFilter,
      accountFilter,
      serviceNameFilter
    ]
      .filter(Boolean)
      .join('&')

    const res = await api.get(`/jera/stats-detailed?page=${page}&${query}`)
    return res.data
  }

  const downloadUsageDetailed = async filters => {
    const dateFilter = `fromDate=${filters.period.from}&toDate=${filters.period
      .to || filters.period.from}`
    const originFilter = filters.origin && `origin=${filters.origin.id}`
    const packageFilter = filters.package && `packageId=${filters.package.id}`
    const accountFilter = filters.account && `accountId=${filters.account.id}`
    const serviceNameFilter =
      filters.serviceName && `codeName=${filters.serviceName}`

    const query = [
      dateFilter,
      originFilter,
      packageFilter,
      accountFilter,
      serviceNameFilter
    ]
      .filter(Boolean)
      .join('&')

    const res = await api.get(`/jera/stats-detailed/export?${query}`)
    return res.data
  }

  const fetchFiltersOptions = async () => {
    const [packages, accounts] = await Promise.allSettled([
      api.get('/jera/packages'),
      api.get('/jera/accounts')
    ])

    return {
      packages: packages.value.data,
      accounts: accounts.value.data,
      origins: [
        { name: 'incoming', id: 'term' },
        { name: 'outgoing', id: 'orig' }
      ]
    }
  }

  return {
    fetchUsageSummary,
    fetchUsageDetailed,
    downloadUsageDetailed,
    fetchFiltersOptions
  }
})()

export default UsageService
